<template>
  <div class="Userhl_dingdan Userhl_rightbox">
    <div class="Userhl_dingdantbox">
      <span>类型</span>
      <el-select v-model="classid" placeholder="请选择类型">
        <el-option
          v-for="item in typeall"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>状态</span>
      <el-select v-model="pay_state" placeholder="请选择状态">
        <el-option
          v-for="item in ztall"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>查询周期</span>
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="timeall"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-button @click="sousuo">搜索</el-button>
    </div>
    <div class="Userhl_home_b_sbox">
      <el-table :data="tableData" border style="width: 100%" stripe>
        <el-table-column prop="order_code" label="订单编号" style="">
        </el-table-column>
        <el-table-column prop="classid" label="订单类型" style="">
        </el-table-column>
        <el-table-column prop="title" label="名称"></el-table-column>
        <el-table-column prop="money" label="金额"></el-table-column>
        <el-table-column prop="create_time" label="时间"></el-table-column>
        <el-table-column prop="pay_status" label="状态"></el-table-column>
      </el-table>
      <el-pagination
        :pager-count="5"
        @current-change="currentchange"
        :page-size="pagesize"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      typeall: [
        {
          value: "1",
          label: "项目任务",
        },
        {
          value: "4",
          label: "购买作品",
        },
        {
          value: "6",
          label: "购买简历",
        },
        {
          value: "7",
          label: "购买课程",
        },
      ],
      ztall: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "尚未支付",
        },
        {
          value: "1",
          label: "已支付",
        },
        {
          value: "2",
          label: "支付失败",
        },
        {
          value: "3",
          label: "订单失效",
        },
      ],
      timeall: "",
      tableData: [],
      pagesize: 10,
      total: 0,
      pay_state: "",
      classid: "",
      userinfo: {},
    };
  },
  methods: {
    currentchange(index) {
      this.axiosqq(index);
    },
    sousuo() {
      this.axiosqq(1);
    },
    axiosqq(index) {
      var that = this;
      that.tableData = [];
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/myOrder",
          method: "post",
          data: {
            classid: that.classid,
            pay_state: that.pay_state,
            start_time: that.timeall[0],
            end_time: that.timeall[1],
            page: index,
            limit: that.pagesize,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.total = res.data.result.count;
            for (var i = 0; i < res.data.result.list.length; i++) {
              if (res.data.result.list[i].pay_status == 0) {
                res.data.result.list[i].pay_status = "尚未支付";
              } else if (res.data.result.list[i].pay_status == 1) {
                res.data.result.list[i].pay_status = "已支付";
              } else if (res.data.result.list[i].pay_status == 2) {
                res.data.result.list[i].pay_status = "支付失败";
              } else if (res.data.result.list[i].pay_status == 3) {
                res.data.result.list[i].pay_status = "订单失效";
              } else if (res.data.result.list[i].pay_status == 4) {
                res.data.result.list[i].pay_status = "待审核";
              } else if (res.data.result.list[i].pay_status == 5) {
                res.data.result.list[i].pay_status = "已提现";
              } else if (res.data.result.list[i].pay_status == 6) {
                res.data.result.list[i].pay_status = "拒绝提现";
              }
              if (res.data.result.list[i].classid == 1) {
                res.data.result.list[i].classid = "项目任务";
              } else if (res.data.result.list[i].classid == 4) {
                res.data.result.list[i].classid = "购买作品";
              } else if (res.data.result.list[i].classid == 6) {
                res.data.result.list[i].classid = "购买简历";
              } else if (res.data.result.list[i].classid == 7) {
                res.data.result.list[i].classid = "购买课程";
              } else if (res.data.result.list[i].classid == 10) {
                res.data.result.list[i].classid = "保证金";
              } else if (res.data.result.list[i].classid == 11) {
                res.data.result.list[i].classid = "余额提现";
              } else if (res.data.result.list[i].classid == 12) {
                res.data.result.list[i].classid = "充值金额";
              }
            }
            that.tableData = res.data.result.list;
          } else {
            console.log("请求失败");
          }
        });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.axiosqq(1);
  },
};
</script>
